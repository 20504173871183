import React from "react";
import HomePageTitle from "../components/Home/HomePageTitle";
import DashboardCards from "../components/Home/DashboardCards";
import CustomNavbar from "../components/navbar/CustomNavbar";


const HomePage = () => {
    return (
      <>
      <CustomNavbar/>
      <HomePageTitle/>
      <DashboardCards/>
      </>);
  }
  
  export default HomePage;