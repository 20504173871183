import React from "react";
import './index.css';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PleaseLogin = () => {
    const navigate = useNavigate();
    return (
      <>
      <Card className="auth-card">
      <Card.Body>
        <Card.Text className="message">Please login before you start</Card.Text>
        <div className="button-container">
        <Button variant="primary" className="auth-button" onClick={()=>{navigate('../login')}}>Login</Button>
        <Button variant="secondary" className="auth-button" onClick={()=>{navigate('../signup')}}>Signup</Button>
        </div>
      </Card.Body>
    </Card>
      </>);
  }
  
  export default PleaseLogin;