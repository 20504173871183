import React, { useState,useEffect } from 'react';
import Register from '../components/signup/Register';
import MessageIfLogin from '../components/common/MessageIfLogin';
import CustomNavbar from '../components/navbar/CustomNavbar';

const RegisterPage = () => {
  
  return (
    <>
    <CustomNavbar/>
    {window.loggeduser ? (
        <>
        <MessageIfLogin/>
        </>
        ) : (
          <Register/>
        )}
    </>
  );
};

export default RegisterPage;
