import React from "react";
import WeddingCard from "./WeddingForm";
import ShowRecordData from "./ShowRecordData";
import "firebase/auth";
import PleaseLogin from "../../common/PleaseLogin";
import PleaseVerifyEmail from "../../common/PleaseVerifyEmail";

const CreateCard = () => {

    return (
      <>
      {window.loggeduser ? (
      <>
      {window.loggeduser.emailVerified ? (
      <>
      <WeddingCard/>
      <ShowRecordData/>
      </>) : (
      <>
      <center><PleaseVerifyEmail/></center>
      </>)}
      </>) : (
      <>
      <center><PleaseLogin/></center>
      </>)}
      </>);
  }
  
  export default CreateCard;