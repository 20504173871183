import React from 'react';
import './index.css'; // Import CSS file for styling

const AboutSection = () => {
  return (
    <>
      <header>
        <h1>DInviCard</h1>
        <p>Welcome to DInviCard</p>
      </header>
        <center>
        <img
        src={require('../../resources/images/dinvicard-logo.PNG')}
        alt="Profile"
        style={{ borderRadius: '50%', width: '100px', height: '100px' }}
      />
        </center>
        <p style={{fontSize: "14px" ,  padding: "20px", textAlign: 'center'}}>Welcome to DInviCard! We are passionate about celebrating life's special moments with creativity and convenience. Our platform empowers you to effortlessly craft unique and personalized digital invitation cards for every occasion. Whether it's a birthday bash, a wedding extravaganza, a baby shower, or any other event close to your heart, our user-friendly interface and diverse design options allow you to unleash your imagination and create invitations that reflect your style and the essence of your event. We believe in making the invitation process enjoyable and stress-free, ensuring that each card is not just an invitation but a delightful preview of the event to come. Join us in embracing the art of digital invitations and let's celebrate together in style!</p>

    </>
    
  );
}

export default AboutSection;
