import React, { useState } from 'react';
import './index.css';
import {login} from '../../auth/FirePerform';
import 'firebase/auth';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      var output = login(formData.email, formData.password)
    }
    catch(e){
      console.log(e)
    }
  };


  return (
    <>
    <div className="form-bg">
    <div className="container">
        <div className="row">
            <div>
                <div className="form-container">
                    <div className="form-icon">
                        <i className="fa fa-user-circle"></i>
                        <span className="signup"><a href="../signup">Don't have account? Signup</a></span>
                    </div>
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <h3 className="title">User Login</h3>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-envelope"></i></span>
                            <input className="form-control" type="email" id="email" name="email" placeholder="Email Address" value={formData.email.toString()} onChange={handleChange}></input>
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-lock"></i></span>
                            <input className="form-control" type="password" id="password" name="password" placeholder="Password" value={formData.password.toString()} onChange={handleChange}></input>
                        </div>
                        <button className="btn signin" type="submit">Login</button>
                        <span className="forgot-pass"><a href="../reset">Forgot Username/Password?</a></span>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

    </>
  );
};

export default Login;
