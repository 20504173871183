import React from "react";
import './index.css';
import CustomNavbar from "../components/navbar/CustomNavbar";

const PageNotFoundpage = () => {
    return (
      <>
      <CustomNavbar/>
      <div className="page-not-found">
      <div className="content">
        <h1>Oops! Page Not Found</h1>
        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        {/* You can add a button or link here to navigate back */}
      </div>
    </div>
      </>);
  }
  
  export default PageNotFoundpage;