import React, {useEffect,useState} from 'react';
import './index.css';
import Login from '../components/login/Login';
import {islogin} from '../auth/FirePerform'
import MessageIfLogin from '../components/common/MessageIfLogin';
import CustomNavbar from '../components/navbar/CustomNavbar';

const LoginPage = () => {
  return (
    <>
    <CustomNavbar/>
    {window.loggeduser ? (
        <>
        <MessageIfLogin/>
        </>
        ) : (
    <Login/>
        )}
    </>
  );
};

export default LoginPage;
