import { Container, Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'; 


const DashboardCards = () => {

    const navigate = useNavigate();

    return (
      <>
     <Container className="mt-4">
      <h3>Create Your Invitation Card</h3>
      <Row className="mt-4">
        <Col md={4}>
          <Card onClick={()=>{navigate('./create')}}>
            <Card.Body>
              <Card.Title>Wedding</Card.Title>
              <img src={require('../../resources/images/Indian wedding-amico.png')} className="img-fluid animated" alt="" style={{width:100}}/>
              <Card.Text>
                Wedding/Reception Card
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card onClick={()=>{navigate('./soon')}}>
            <Card.Body>
              <Card.Title>Birthday</Card.Title>
              <img src={require('../../resources/images/Birthday cake-bro.png')} className="img-fluid animated" alt="" style={{width:100}}/>
              <Card.Text>
                Birthday Celebration Invitation
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card onClick={()=>{navigate('./soon')}}>
            <Card.Body>
              <Card.Title>Rice Ceremony</Card.Title>
              <img src={require('../../resources/images/Happy baby-cuate.png')} className="img-fluid animated" alt="" style={{width:100}}/>
              <Card.Text>
              Rice Ceremony Program
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    
      </>);
  }
  
  export default DashboardCards;