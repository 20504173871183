import React from "react";
import './index.css';
import CustomNavbar from "../components/navbar/CustomNavbar";

const ComingSoonPage = () => {
    return (
      <>
      <CustomNavbar/>
    <center><div className="container">
  <div className="row">
    <div className="col-md-12 text-center">
      <h3 className="animate-charcter"> Coming Soon</h3>
    </div>
  </div>
</div></center>
    
      </>);
  }
  export default ComingSoonPage;