import React from 'react';
import './index.css'; // Import CSS file for styling

function Portfolio() {
  return (
    <div className="portfolio">
      <header>
        <h1>My Portfolio</h1>
        <p>Welcome to my portfolio page!</p>
      </header>
      <main>
        <center>
        <img
        src={require('../../resources/images/myphoto.jpg')}
        alt="Profile"
        style={{ borderRadius: '50%', width: '150px', height: '150px' }}
      />
        </center>
        <p style={{fontSize: "14px" ,  padding: "20px", textAlign: 'center'}} >Hi everyone, I am <strong>Shuvasis Sarkar</strong>. Currently working in the software industry. This is ony of my projects where anyone can register and 
        generate a <strong>Digital Invitation Card (DInviCard)</strong> very easily and quickly share it with all.<br/>If you really like this project, please let me know and your feedback is very much valuable for me. Thank you. </p>
        
        <div className="social-media-links">
      <h2>Connect with Me</h2>
      <div className="social-icons">
        <a href="https://www.facebook.com/shuvasis.sarkar.7" target="_blank" rel="noopener noreferrer">
          <img src={require('../../resources/images/fb_icon_325x325.png')} alt="Facebook" />
        </a>
        <a href="https://github.com/scorpion004" target="_blank" rel="noopener noreferrer">
          <img src={require('../../resources/images/github.png')} alt="GitHub" />
        </a>
        <a href="mailto:shuvasis.sarkar@gmail.com">
          <img src={require('../../resources/images/gmail.png')} alt="Gmail" />
        </a>
        <a href="https://www.linkedin.com/in/sarkar-shuvasis" target="_blank" rel="noopener noreferrer">
          <img src={require('../../resources/images/linkedin-icon-2048x2048-ya5g47j2.png')} alt="LinkedIn" />
        </a>
        <a href="https://www.codewat.com" target="_blank" rel="noopener noreferrer">
          <img src={require('../../resources/images/codewat.png')} alt="LinkedIn" />
        </a>
      </div>
    </div>
      </main>
      
    </div>
    
  );
}

export default Portfolio;
