import React from "react";
import QRCode from "react-qr-code";
import './QrMaker.css'

const QrMaker = ({url}) => {
    return (
      <>
      <center>
      <div className="qr-cont">
      <QRCode value={url} size={100} fgColor={"#e60012"}/>
      </div>
      </center>
      </>);
  }
  
  export default QrMaker;