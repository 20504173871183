import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './index.css';
import * as Icon from 'react-bootstrap-icons';

function Footer() {
  return (
    <>

<div className="footer-dark">
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col item social"><a href="https://www.facebook.com/shuvasis.sarkar.7"><i><Icon.Facebook/></i></a><a href="https://github.com/scorpion004"><i><Icon.Github/></i></a><a href="tel:"><i><Icon.Phone/></i></a><a href="mailto:shuvasis.sarkar@gmail.com"><i><Icon.Mailbox/></i></a></div>
                </div>
                <p className="copyright">DInviCard © 2023</p>
            </div>
            
        </footer>
    </div>
    </>
  );
}

export default Footer;