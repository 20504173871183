import React, { useEffect,useState, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.css';
import { useParams } from 'react-router-dom';
import {db} from "../../auth/Firebase"
import "firebase/auth";
import { collection, getDocs } from "firebase/firestore"; 
import { query } from "firebase/firestore";
import CardNavbar from "../navbar/CardNavbar";
import QrMaker from "../QR/QrMaker";
import { Modal, Button } from 'react-bootstrap';

const FinalWeddingCard= () => {

  const params = useParams();
  const [cardFound, setCardfound] = useState(false)
  const [showModal, setShowModal] = useState(false);


  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleRowClick = () => {
    setShowModal(true);
  };

  const [cardinfo, setCardinfo] = useState({
    bride_name: '',
    groom_name: '',
    wedding_date: '',
    reception_date: '',
    venue:'',
    map_location:'',
    contact_number:'',
    invite_for: '',
    id: ''
  });

  useEffect(() => {
    Fetchdata();
  }, []);

  // Fetch the required data using the get() method
  const Fetchdata = async() => {
    const q = query(collection(db, "invitationData"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if(doc.data()['id']===params.cardId){
        setCardinfo(doc.data())
        setCardfound(true)
      }
    }
    );
    }

    const bride=cardinfo['bride_name']
    const groom=cardinfo['groom_name']
    const weddingDate= cardinfo['wedding_date']
    const receptionDate=cardinfo['reception_date']
    const venue=cardinfo['venue']
    const venueLocation = cardinfo['map_location']
    const contact=cardinfo['contact_number']
    const invite_for=cardinfo['invite_for']

    return (
      <>
      <CardNavbar/>
      {cardFound === false ? (
        <>
        <div className="loader"></div>
        <center><p>Searching Card</p></center>
        </>
      ) : (
        <>
        <Container className="wedding-card">
      <Row>
        <Col><img src={require('../../resources/images/kulo.png')} className="img-fluid animated" alt=""/></Col>
        <Col><img src={require('../../resources/images/groom_bride.jpg')} className="img-fluid animated" alt="" /></Col>
        <Col><img src={require('../../resources/images/kulo.png')} className="img-fluid animated" alt="" /></Col>
      </Row>
      <Row><p className="custom-text" >You Are Cordially <br/>Invited to Attend The {invite_for} Of</p></Row>

    <Row>
      <Col sm> 
      <center>
        <h1 className="custom-text">{groom}</h1>
      </center>
      </Col >
      <Col sm><center><p className="custom-text">with</p></center></Col>
      <Col sm> 
      <center>
      <h1 className="custom-text">{bride}</h1>
      </center>
      </Col>
    </Row>
      <Row><center><Col><img src={require('../../resources/images/kolka.png')} className="img-fluid animated" alt="" style={{width:200}}/></Col></center></Row>
    <Row>
    <center><h3 className="custom-text">Wedding Ceremony: <strong>{weddingDate}</strong></h3></center>
    <center><h3 className="custom-text">Reception Program: <strong>{receptionDate}</strong></h3></center>
    <center><h3 className="custom-text">Venue: <strong>{venue}</strong></h3></center>
    </Row>
    
    <Row><center><Col><img src={require('../../resources/images/kolka.png')} className="img-fluid animated" alt="" style={{width:200}} /></Col> </center></Row>
    <Row className="justify-content-center">
      <Col xs="auto">
      <a href={venueLocation} >
        <img
          src={require('../../resources/images/map_icon.gif')}
          loading="lazy"
          style={{ width: "70px", height: "70px" }}
          alt=""
        />
      </a>
      </Col>
      <Col xs="auto">
      <a href={"tel:" + contact}>
        <img
          src={require('../../resources/images/phone_icon.gif')}
          loading="lazy"
          style={{ width: "70px", height: "70px" }}
          alt=""
        />
        </a>
      </Col>

      <Col xs="auto">
        <img
          src={require('../../resources/images/qr-code.gif')}
          loading="lazy"
          style={{ width: "70px", height: "70px" }}
          alt=""
          onClick={()=>{handleRowClick()}}
        />
      </Col>
    </Row>
    </Container>

    <center>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>QR code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <QrMaker url={window.location.href}/>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="danger" onClick={downloadQRCode}>Download</Button> */}
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          
        </Modal.Footer>
      </Modal>
        </center>
        </>
      )}
      </>);
  }
  
  export default FinalWeddingCard;