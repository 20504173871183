import {getAuth,signInWithEmailAndPassword,onAuthStateChanged,signOut,createUserWithEmailAndPassword,sendPasswordResetEmail,sendEmailVerification } from "firebase/auth"
import {deleteDoc,doc,updateDoc} from 'firebase/firestore'
import {db} from "./Firebase"
import { collection, getDocs } from "firebase/firestore"; 
import { query,where  } from "firebase/firestore";
import { setDoc } from "firebase/firestore";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = getAuth();

export const getCurrentUser = async () =>{
    await onAuthStateChanged(auth, (user) => {
     if (user) {
    //    console.log('logged in')
       return(user)
     } else {
        // console.log('logged out')
        return null
     }
   });
 }

export const logout =async()=>{
    try{
        try{
        signOut(auth).then(() => {
            toast.info("Sign-out successful.")
            window.location.reload();
        }).catch((error) => {
            toast.error('Something Wrong ! Try again')
        })
        }
        catch(e){
            toast.error('Something Wrong ! Try again')
        }
    }
    catch(e){
        toast.error('Something Wrong ! Try again')
    }
    }

export const login = async(email,password)=>{
    try{
        // var x = await account.createEmailSession(formData.email,formData.password)
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        if(user){
            if(user.emailVerified === true){
                // console.log("Logged in Successfully!!!!")
                toast.success("Logged in Successfully!!!!")
                return user
            }
            else{
                // console.log('Email is not verified. Please verify before login')
                toast.info("Email is not verified. Please verify")
            }
        }
        else{
            // alert('Invalid Credentials / user is not present')
            toast.error("Invalid Credentials / user is not present")
        }
        // navigate('/create')
        })
        .catch((error) => {
        // const errorMessage = error.message;
        // alert(errorMessage)
        toast.error("Invalid Credentials / user is not present")
        return null
        });
        
    }
    catch(e){
    alert("Invalid credentials !")
    return null
    }
    
    }
export const register = async(email, password)=>{
        try{
          // var x = await account.create('unique()',formData.email,formData.password,formData.username)
          // console.log(x)
          createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            if(user){
                sendEmailVerification(user)
                .then(() => {
                    // alert('Email verification mail sent ! Pleasee verify before login')
                    toast.success('Email verification mail sent ! Please verify before login')
                    })
            }
            // ...
          })
          .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // console.log(errorMessage)
            toast.error('Failed to Register')
            // ..
          });
        }
        catch(e){
        // console.log(e)
        toast.error('Failed to Register')
        }
      }

export const delvalue = async(id) =>{
        try{
            if(window.confirm("Are you sure to delete ?"))
            {
                const dataRef = doc(db, "invitationData", id);
                deleteDoc(dataRef)
                .then(() => {
                    toast.success("Deleted successfully.")
                })
                .catch(error => {
                    // console.log(error);
                    toast.error("Something Wrong ! Please try again")
                })
            }
        }
        catch(e){
            toast.error("Something wrong ! Try again")
            // console.log(e)
        }
    }

export const sendVerification = async(user) => {
    try {
        await sendEmailVerification(user).then(() => {
            toast.info('Email verification mail sent ! Please verify')
            });    
    }
    catch(e){
        toast.error("Something wrong ! Try after some time")
        // console.log(e)
    }
}

export const submitCard = async(formData,userRecord,inviteFor) =>{
    try{
        await setDoc(doc(db, "invitationData", Math.random().toString(36)), {
            groom_name: formData.groomName,
            bride_name: formData.brideName,
            reception_date: moment(formData.receptiondate).format('dddd, MMMM DD, YYYY'), //formData.receptiondate
            wedding_date: moment(formData.weddingdate).format('dddd, MMMM DD, YYYY'),
            venue:formData.venue,
            created_by: userRecord.email,
            map_location: formData.maplocation,
            contact_number: formData.contactnumber,
            invite_for: inviteFor,
            id: Math.random().toString(16).slice(-6).toString()

        });
        toast.success("Submit Done ! Please Refresh")
    }
    catch(e){
        toast.error("Something wrong ! Try again")
        // console.log(e)
    }
}

export const fetchCreatedCarddata = async(userRecord) => {
    if(userRecord != null){
      const q = await query(collection(db, "invitationData"),where("created_by", "==", userRecord.email)); //,where("created_by", "==", userRecord.email)
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        docid: doc.id,
        ...doc.data(),
      }));
      return fetchedData
    }
    return []
    }

export const sendPasswordReset = async(email) => {
    try{
        await sendPasswordResetEmail(auth,email).then(
            toast.success("Password Reset Mail Sent Successfully")
        )
    }
    catch(e){
        toast.error("Something wrong ! Try again")
        console.log(e)
    }
}

export const updateWeddingCard = async(docId,updatedData) =>{
    try{
        console.log(docId)
        console.log(updatedData)
        const dataRef = doc(db, "invitationData", docId);
        // await db.collection('invitationData').doc(docId).update(updatedData);
        updateDoc(dataRef,updatedData)
        toast.success("Updated Successfully")
    }
    catch(e){
        toast.error("Something wrong ! Try again")
        // console.log(e)
    }
}

