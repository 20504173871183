import React, { useState,useEffect } from 'react';
import './index.css';
import { Navigate, useNavigate } from 'react-router-dom'; 
import {register,sendVerification,logout} from '../../auth/FirePerform';
import {getAuth,onAuthStateChanged} from "firebase/auth"

const auth = getAuth()

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confpassword: '',
  });

  const [loginFlag, setLoginFlag] = useState(null)

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(formData.email ==="" || formData.username==="" || formData.password==="" || formData.confpassword===""){
      alert("Please fill all fields")
    }
    else {
      if(formData.password != formData.confpassword){
        alert("Please enter passowrd correctly")
      }
      else {
        register(formData.email,formData.password).finally(navigate('/login'));
      }
      
    }
  };

//   useEffect(()=>{
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         console.log('logged in')
//         setLoginFlag( user)
//       } else {
//          console.log('logged out')
//          setLoginFlag(null)
//       }
//     });
//    },[])

  return (
    <>
    <div className="form-bg">
    <div className="container">
        <div className="row">
            <div>
                <div className="form-container">
                    <div className="form-icon">
                        <i className="fa fa-user-circle"></i>
                        <span className="signup"><a href="../login">Already have account? Login</a></span>
                    </div>
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <h3 className="title">User Register</h3>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-envelope"></i></span>
                            <input className="form-control" placeholder='User Name' type="text" id="username" name="username" value={formData.username} onChange={handleChange} required></input>
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-envelope"></i></span>
                            <input className="form-control" placeholder='Email' type="email" id="email" name="email" value={formData.email} onChange={handleChange} required></input>
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-lock"></i></span>
                            <input className="form-control" placeholder='Password' type="password" id="password" name="password" value={formData.password} onChange={handleChange} required></input>
                        </div>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-lock"></i></span>
                            <input className="form-control" placeholder='Confirm Password' type="password" id="confpassword" name="confpassword" value={formData.confpassword} onChange={handleChange} required></input>
                        </div>
                        <button className="btn signin" type="submit">Login</button>
                        <span className="forgot-pass"><a href="../reset">Forgot Username/Password?</a></span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

    </>
  );
};

export default Register;
