import React from "react";
import { Container, Row, Col, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 

const HomePageTitle = () => {

    const navigate = useNavigate();

  return (
    <>
  <div className="homepage">
        <Container>
          <h1>Digital Invitation Card</h1>
          <p>
            Make your momement memorable
          </p>
          <img src={require('../../resources/images/Indian wedding-bro.png')} className="img-fluid animated" alt="" style={{width:400}}/>
          
        </Container>

      <Container>
        <Row>
          <Col>
            <h2>DInviCard</h2>
            <p>
            Crafting personalized digital invitations for every milestone. Celebrate life's moments with ease and creativity.
            </p>
            <p>
            <Button variant="info"  onClick={()=>{navigate('./about')}}>Learn more</Button>
          </p>
          </Col>
          <Row>
            
          </Row>
        </Row>
      </Container>
    </div>
   
    </>);
}

export default HomePageTitle;