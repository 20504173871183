import React from "react";
import ComingSoonPage from "./ComingSoonPage";
import Portfolio from "../components/portfolio/Portfolio";
import CustomNavbar from "../components/navbar/CustomNavbar";
import AboutSection from "../components/portfolio/AboutSection";

const AboutPage = () => {
    return (
      <>
      <CustomNavbar/>
      <AboutSection/>
    <Portfolio/>
      </>);
  }
  
  export default AboutPage;