import React, { useEffect, useState } from 'react';
import './index.css';
import "firebase/auth";
import {submitCard,fetchCreatedCarddata} from '../../../auth/FirePerform';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WeddingCard = () => {
    // const [userData, setUserData] = useState('');
    const [selectedInviteFor, setSelectedInviteFor] = useState('Wedding');
    const [count, setCount] = useState(null);

    const Fetchdatacount = async(userRecord)=>{
        const output = await fetchCreatedCarddata(userRecord)
        setCount(output.length)
      };

      useEffect(()=>{
        // fetchUserData()
        Fetchdatacount(window.loggeduser)
      },[])

    const [formData, setFormData] = useState({
        groomName: '',
        brideName: '',
        venue: '',
        weddingdate: '',
        logged_by_id: '',
        receptiondate:'',
        maplocation:'',
        contactnumber:'',
        invitefor:''
      });
    

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value.toString(),
    });
    
    };

    const handleChangeInviteFor = () => {
        setSelectedInviteFor(selectedInviteFor === 'Reception' ? 'Wedding' : 'Reception');
      };


    const handleSubmit = (e) => {
        e.preventDefault();
        Fetchdatacount(window.loggeduser)
        if(formData.groomName ==="" || formData.brideName==="" || formData.venue==="" || formData.weddingdate==="" || formData.receptiondate==="" || formData.maplocation==="" || formData.contactnumber===""){
            alert("Please fill all fields")
          }
        else {
            if(count >= 5){
                toast.warning("Maximum 5 cards allowed. Please delete some cards")
            }
            else{
                submitCard(formData,window.loggeduser,selectedInviteFor)
                setFormData({
                    groomName: '',
                    brideName: '',
                    venue: '',
                    weddingdate: '',
                    logged_by_id: '',
                    receptiondate:'',
                    maplocation:'',
                    contactnumber:'',
                    invitefor:''
                })
                // toast.success('Data submitted successfully! Please Refresh');
                
            }
        }
        Fetchdatacount(window.loggeduser)
      };
      
    return (
      <>
      <div className="create-form-container">
      <h2>Wedding and reception Form</h2>
      <center>
      <form onSubmit={handleSubmit}>
        <div className="create-form-group">
        <input
            type="text"
            id="groomName"
            name="groomName"
            placeholder='Groom Name'
            value={formData.groomName.toString()}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <input
            type="text"
            id="brideName"
            name="brideName"
            placeholder='Bride Name'
            value={formData.brideName.toString()}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <input
            type="text"
            id="venue"
            name="venue"
            placeholder='Venue Address'
            value={formData.venue.toString()}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label htmlFor="selectedDate">Select Wedding Date</label>
        <input
            type="date"
            id="weddingdate"
            name="weddingdate"
            placeholder='Wedding Date'
            value={formData.weddingdate.getDate}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <label htmlFor="selectedDate">Select Reception Date</label>
        <input
            type="date"
            id="receptiondate"
            name="receptiondate"
            placeholder='receptiondate'
            value={formData.receptiondate.getDate}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <input
            type="text"
            id="maplocation"
            name="maplocation"
            placeholder='Venue Location URL'
            value={formData.maplocation.toString()}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <input
            type="text"
            id="contactnumber"
            name="contactnumber"
            placeholder='Contact Number'
            value={formData.contactnumber.toString()}
            onChange={handleChange}
        />
        </div>
        <div className="form-group">
        <Form.Check
        type="switch"
        id="custom-switch"
        label={`${selectedInviteFor === 'Reception' ? 'Reception' : 'Wedding'}`}
        checked={selectedInviteFor === 'Reception'}
        onChange={handleChangeInviteFor}
        />
        </div>
        <button type="submit">Submit</button>
      </form>
      </center>
    </div>
      
      </>);
  }
  
  export default WeddingCard;