import "bootstrap/dist/css/bootstrap.css";
import HomePage from './pages/HomePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreatePage from './pages/CreatePage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import AboutPage from './pages/AboutPage';
import Footer from './components/footer/Footer';
import FinalWeddingCard from './components/CardShow/FinalWeddingCard';
import PasswordResetPage from './pages/PasswordResetPage'
import PageNotFoundpage from './pages/PageNotFoundpage';
import ComingSoonPage from './pages/ComingSoonPage';
import { ToastContainer } from 'react-toastify';

import { useState ,useEffect} from 'react';
import {app} from "./auth/Firebase"
import "firebase/auth";
import {getAuth ,onAuthStateChanged } from "firebase/auth"

const auth = getAuth(app);

function App() {

  const [userRecord, setUserRecord] = useState(null);

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserRecord( user)
      } else {
         setUserRecord(null)
      }
    });
  },[])

  window.loggeduser = userRecord; 

  return (
    <>
    {/* <CustomNavbar/> */}
    <ToastContainer />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/signup" element={<RegisterPage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/about" element={<AboutPage/>}/>
      <Route path="/create" element={<CreatePage/>}/>
      <Route path="/reset" element={<PasswordResetPage/>}/>
      <Route path="/soon" element={<ComingSoonPage/>}/>
      <Route path="*" element={<PageNotFoundpage/>}/>
      <Route path="/showweddingcard/:cardId" element={<FinalWeddingCard />} />
    </Routes>
    </BrowserRouter>
    <Footer/>
    </>
  );
}

export default App;
