import React from "react";
import './index.css';
import { Card, Button } from 'react-bootstrap';
import {logout,sendVerification} from '../../auth/FirePerform';

const MessageIfLogin = () => {
    return (
      <>
      <Card className="auth-card">
      <Card.Body>
    <center>
      <p>Hi <b>{window.loggeduser.email}</b></p>
      {window.loggeduser.emailVerified ? (
        <p>Email is verified</p>
      ):(
        <>
        <p>Please Verify your Email ID</p>
        <p>Didn't received verification email ? <Button variant="warning" onClick={()=>{sendVerification(window.loggeduser)}}>Click here</Button></p>
        </>
      )}
      <p>Already Logged in</p>
      <Button onClick={logout} variant="danger">Logout</Button>
      </center>
      </Card.Body>
    </Card>
      </>);
  }
  
  export default MessageIfLogin;