import React from "react";
import './index.css';
import { Card, Button } from 'react-bootstrap';
import {logout,sendVerification} from '../../auth/FirePerform';

const PleaseVerifyEmail = () => {
    return (
      <>
      <Card className="auth-card">
      <Card.Body>
        <Card.Text className="message">Please Verify your email</Card.Text>
        <div className="button-container">
        <Button variant="warning" className="auth-button" onClick={()=>{sendVerification(window.loggeduser)}}>Send Verification Email</Button>
        <Button variant="danger" className="auth-button" onClick={logout}>Logout</Button>
        </div>
      </Card.Body>
    </Card>
      </>);
  }
  
  export default PleaseVerifyEmail;