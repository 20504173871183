import React, { useEffect,useState } from "react";
import {delvalue,fetchCreatedCarddata,updateWeddingCard} from '../../../auth/FirePerform';
import { PlayBtnFill } from 'react-bootstrap-icons'
import './index.css';
import { Modal, Button } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ShowRecordData = () => {

    const [info, setInfo] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editedFields, setEditedFields] = useState({})

    // const copyToClipboard = async () => {
    //   try {
    //     await navigator.clipboard.writeText(window.location.origin + '/showweddingcard/' + selectedItem.id);
    //     alert('Copied to clipboard!');
    //   } catch (err) {
    //     console.error('Failed to copy:', err);
    //   }
    //   setShowModal(false);
    //   setEditedFields({});
    // };
    
    const Fetchdata = async(userRecord)=>{
      const output = await fetchCreatedCarddata(userRecord)
      setInfo(output)
    };

    const handleRowClick = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
      setEditedFields({}); // Reset edited fields when closing modal
    };
  
    const handleFieldChange = (e) => {
      const { name, value } = e.target;
      setEditedFields({ ...editedFields, [name]: value });
    };
  
    const handleUpdate = async () => {
      updateWeddingCard(selectedItem.docid,editedFields)
      // const db = firebase.firestore();
      // await db.collection('yourCollection').doc(selectedItem.id).update(editedFields);
      setShowModal(false);
      setEditedFields({});
      Fetchdata(window.loggeduser)
    };

    const handleDelete = async () => {
      delvalue(selectedItem.docid)
      // const db = firebase.firestore();
      // await db.collection('yourCollection').doc(selectedItem.id).update(editedFields);
      setShowModal(false);
      setEditedFields({});
      Fetchdata(window.loggeduser)
    };

    const refreshTable = async () =>{
      Fetchdata(window.loggeduser)
    }

    useEffect(()=>{
      // fetchUserData()
      Fetchdata(window.loggeduser)
    },[])


    return (
      <>
      {
        info.length === 0 ? (
          <><center><h1>No Card Data Found ! Create one</h1></center>
          <center><button type="button" className="btn btn-primary btn-circle"  onClick={() => refreshTable()}><i className="bi bi-arrow-clockwise"></i></button></center>
          </>
        ) : (
          <>
        <div className="container mt-4">
      <h1>Your Cards</h1>
      <button type="button" className="btn btn-primary btn-circle"  onClick={() => refreshTable()}><i className="bi bi-arrow-clockwise"></i></button>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
            <th>ID</th>
              <th>Groom</th>
              <th>Bride</th>
              <th>Wedding</th>
              <th>Reception</th>
              <th>Venue</th>
              <th>Venue Location</th>
              <th>Contact</th>
              <th>Invite For</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody>
          {info.map(item => (
              <tr key={item.id} onClick={() => handleRowClick(item)}>
                <td >{item.id}</td>
                <td>{item.groom_name}</td>
                <td>{item.bride_name}</td>
                <td>{item.wedding_date}</td>
                <td>{item.reception_date}</td>
                <td>{item.venue}</td>
                <td>{item.map_location}</td>
                <td>{item.contact_number}</td>
                <td>{item.invite_for}</td>
                <td>
                  {/* <button onClick={()=> delvalue(item.docid).then()} ><TrashFill/></button> */}
                  <a href={'/showweddingcard/' + item.id}><button><PlayBtnFill/></button></a>
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>


    {/* Modal for editing */}
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <form>
              {/* Render form fields based on selected item */}
              <div className="mb-3">
                <Row>
                  <Col><label htmlFor="groom_name" className="form-label">
                Groom Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="groom_name"
                  name="groom_name"
                  value={editedFields.groom_name || selectedItem.groom_name}
                  onChange={handleFieldChange}
                /></Col>
                <Col>
                <label htmlFor="groom_name" className="form-label">
                Bride Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bride_name"
                  name="bride_name"
                  value={editedFields.bride_name || selectedItem.bride_name}
                  onChange={handleFieldChange}
                />
                </Col>
                </Row>
                <Row>
                  <Col>
                  <label htmlFor="wedding_date" className="form-label">
                Wedding Date
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="wedding_date"
                  name="wedding_date"
                  value={editedFields.wedding_date || selectedItem.wedding_date}
                  onChange={handleFieldChange}
                />
                  </Col>
                  <Col>
                  <label htmlFor="reception_date" className="form-label">
                Reception Date
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reception_date"
                  name="reception_date"
                  value={editedFields.reception_date || selectedItem.reception_date}
                  onChange={handleFieldChange}
                />
                  </Col>
                </Row>
                <label htmlFor="Venue" className="form-label">
                Venue
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="venue"
                  name="venue"
                  value={editedFields.venue || selectedItem.venue}
                  onChange={handleFieldChange}
                />
                <label htmlFor="map_location" className="form-label">
                Venue Map Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="map_location"
                  name="map_location"
                  value={editedFields.map_location || selectedItem.map_location}
                  onChange={handleFieldChange}
                />
                <Row>
                  <Col>
                  <label htmlFor="contact_number" className="form-label">
                Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_number"
                  name="contact_number"
                  value={editedFields.contact_number || selectedItem.contact_number}
                  onChange={handleFieldChange}
                />
                  </Col>
                  <Col>
                  <label htmlFor="invite_for" className="form-label">
                Invite For
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="invite_for"
                  name="invite_for"
                  value={editedFields.invite_for || selectedItem.invite_for}
                  onChange={handleFieldChange}
                />
                  </Col>
                </Row>
              </div>
              {/* Add other fields here */}
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedItem ? (<a href={window.location.origin + '/showweddingcard/' + selectedItem.id.toString()}
           target="_blank" rel="noopener noreferrer"><Button variant="success" >Preview</Button></a>):(<></>)}
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
          </>
        )
      }
      
      </>);
  }
  

  export default ShowRecordData;