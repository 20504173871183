import React from "react";
import CreateCard from "../components/createCard/Wedding/CreateCard";
import CustomNavbar from "../components/navbar/CustomNavbar";


const CreatePage = () => {
    return (
      <>
      <CustomNavbar/>
    <CreateCard/>
      </>);
  }
  
  export default CreatePage;