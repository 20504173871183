import React, {useEffect,useState} from 'react';
import './index.css';
import Login from '../components/login/Login';
import {islogin} from '../auth/FirePerform'
import PasswordReset from '../components/passwordReset/PasswordReset';
import CustomNavbar from '../components/navbar/CustomNavbar';

const LoginPage = () => {

  return (
    <>
    <CustomNavbar/>
    <PasswordReset/>
    </>
  );
};

export default LoginPage;
