import React, { useState } from 'react';
import './index.css';
import {sendPasswordReset} from '../../auth/FirePerform'

function PasswordReset() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPasswordReset(email)
    setEmail('')
  };

  return (
    <>
    <div className="form-bg">
    <div className="container">
        <div className="row">
            <div>
                <div className="form-container">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <h3 className="title">Reset Password</h3>
                        <div className="form-group">
                            <span className="input-icon"><i className="fa fa-envelope"></i></span>
                            <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email Address" ></input>
                        </div>
                        <button className="btn signin" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

    </>
  );
}

export default PasswordReset;
